export default {
  cake: {
    56: '0x6aaa14929d74b8533343c1a5b6e42443f59b6f6f',
    97: '0xAa56B5a641F2B5dDF7e8f014463111974AD2F73D',
    80001: "0x056DCB5Fe7645dcA56641C79DB39eB531f2c1af8",
    137: "0x55E29f7280565d6C392c192161de9e57b125165B"
  },
  masterChef: {
    56: '0xea14676617dce5cf81d59867dadcf2795b6b72ff',
    97: '0x477556726F7C3532f19d8015617399669859fFd4',
    80001: "0xD37FD1CF4296540D0a7b412a4FD5F1609eF4BFe7",
    137: "0x6E18e9c61b9A8d6D394f802D0766255f5e4B0899"
  },
  usdt: {
    56: "",
    137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xA73D61379627dB94f968C7bb4BD7fF5c07723Ec2',
    80001: "0x9c3c9283d3e44854697cd22d3faa240cfb032889",
    137: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    80001: "0xBBEa47c3746912809c554b38b426f43ABA411eec",
    137: "0xa1B2b503959aedD81512C37e9dce48164ec6a94d"
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
    80001: "0xe583769738b6dd4e7caf8451050d1948be717679",
    137: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7"
  },
}
